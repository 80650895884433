import ArrowIcon from 'assets/arrow.svg';
import { Button, ButtonIconArrow, ButtonsContainer, ButtonText } from 'components/Button/Button';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import { SectionParagraph } from 'components/SectionParagraph/SectionParagraph';
import Img from 'gatsby-image';
import { Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import QuoteIcon from '../assets/quote.svg';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import * as Styled from '../components/Quote/QuoteStyles';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';
import { useImageQuery } from '../hooks/use-image-query';

const Media: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const dataImage = useImageQuery();

  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={dataImage.error_bg.childImageSharp.fluid} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - 404</title>
            <meta name="description" content="Some content." />
          </Helmet>
          <SectionHeading>Upss...</SectionHeading>
          <SectionParagraph>{formatMessage({ id: 'error.msg' })}</SectionParagraph>
          <ButtonsContainer>
            <Button as={Link} to={'/'} icon="true" aria-label={formatMessage({ id: 'home.title' })}>
              <ButtonText>{formatMessage({ id: 'home.title' })}</ButtonText>
              <ButtonIconArrow className="arrow--icon">
                <ArrowIcon />
              </ButtonIconArrow>
            </Button>
          </ButtonsContainer>
        </ContentRow>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default Media;
